<template>
  <div class="profileEdit">
    <!-- Header -->
    <section class="head">
      <div class="left">
        <a @click="goTo('/profile')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>編輯帳號</span>
      <div class="right"></div>
    </section>
    <section class="main">
      <!-- <img v-if="displayUserImage" :src="displayUserImage" /> -->
      <div class="inputs">
        <div class="input">
          <span>姓名</span>
          <input type="text" placeholder="" v-model="userData.name" />
          <!-- input class: error -->
          <!-- <span class="error">錯誤訊息</span> -->
        </div>
        <div v-if="userInfo.phoneVerified" class="input">
          <span>電話號碼</span>
          <input
            type="text"
            class="disabled"
            placeholder=""
            v-model="userData.phoneNumber"
          />
        </div>
        <div class="input">
          <span>電子信箱</span>
          <input type="email" placeholder="" v-model="userData.email" />
        </div>
        <div class="input">
          <span>生日</span>
          <input type="date" v-model="userData.birthdate" />
        </div>
        <div class="input">
          <span>性別</span>
          <a @click="openCategoryPop">{{ displayedGender }}</a>
        </div>
      </div>
      <div class="buttons">
        <button @click="goTo('/profile')">
          <span>取消</span>
        </button>
        <button @click.prevent="save()">
          <span>儲存</span>
        </button>
      </div>
    </section>
    <!-- 分類popup -->
    <section id="popCategory" class="pop category" @click="closeCategoryPop">
      <div class="popBody" @click.stop>
        <a class="close" @click="closeCategoryPop"
          ><font-awesome-icon
            icon="fa-solid fa-xmark"
            size="lg"
          ></font-awesome-icon
        ></a>
        <span class="title">性別</span>
        <a
          v-for="(gender, index) in genderType"
          :key="'gender_' + index"
          @click="changeGender(gender.value)"
          ><span :class="{ active: userData.gender === gender.value }">{{
            gender.text
          }}</span></a
        >
      </div>
    </section>
    <!-- 訊息popup -->
    <section id="popNotice" class="pop notice" @click="popClose">
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <font-awesome-icon
              v-if="popupContent.type === 'success'"
              icon="fa-regular fa-circle-check"
              size="5x"
            ></font-awesome-icon>
            <font-awesome-icon
              v-else-if="popupContent.type === 'error'"
              icon="fa-regular fa-circle-xmark"
              size="5x"
            ></font-awesome-icon>
          </div>
          <span class="title">{{ popupContent.title }}</span>
          <span>{{ popupContent.message }}</span>
        </div>
        <div class="buttons">
          <button @click="popClose">
            <span>確認</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'ProfileEdit',
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      genderType: [
        {
          value: 0,
          text: '男',
        },
        {
          value: 1,
          text: '女',
        },
        {
          value: null,
          text: '不提供',
        },
      ],
      userData: {
        name: '',
        gender: '',
        birthdate: '',
        phoneNumber: '',
        email: '',
      },
      popupContent: {
        type: '',
        title: '',
        message: '',
      },
    };
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
    // displayUserImage() {
    //   return this.userInfo ? this.userInfo.picture : '';
    // },
    displayedGender() {
      return this.genderType.find(
        gender => gender.value === this.userData.gender
      )?.text;
    },
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    dummyUserData() {
      let result = {
        memberId: 47,
        uuid: null,
        oauthId: 'U38a079aadef4a60ec5a6263112248b35',
        picture:
          'https://profile.line-scdn.net/0hNwmZpXdREVhQIzrAyWRuD2xmHzUnDRcQKEJdOHIlGmt1RFIHOEFXPSUiH2x4EFIHaBZebiErSW4p',
        idno: 'H223292882',
        name: 'Kay Lai',
        birthdate: '1988-03-05',
        phoneNumber: '0921185084',
        carrier: null,
        gender: 1,
        email: 'clearsky0305@gmail.com',
        fullAddress: '桃園市平鎮區',
        isTyCitizen: 1,
        createdTs: 1647945287,
      };
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callUserDataAPI() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        headers: {},
      };
      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUserData() {
      this.callUserDataAPI()
        // this.dummyUserData()
        .then(res => {
          this.$store.commit('updateUserInfo', res.data);
          this.userData = res.data;
        })
        .catch(err => {
          console.log('err: ', err);
          this.goTo('/error');
        });
    },
    popNotice(content) {
      this.popupContent = content;
      popNotice.style.display = 'flex';
    },
    popClose() {
      popNotice.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        popNotice.style.display = 'none';
        popNotice.children[0].style.animationName = 'popup';
      }, 500);
    },
    callUpdateProfileAPI() {
      let data = Object.assign({}, this.userData);
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        method: 'PUT',
        data: data,
      };
      console.log('config: ', config);
      return this.$http(config);
    },
    save() {
      this.callUpdateProfileAPI()
        .then(res => {
          console.log('res: ', res);
          this.popNotice({ type: 'success', title: '已儲存' });
          this.getUserData();
        })
        .catch(err => {
          console.log('err: ', err);
          this.popNotice({
            type: 'error',
            title: '儲存失敗',
            message: '網路錯誤，請稍後重試',
          });
        });
    },
    changeGender(value) {
      this.userData.gender = value;
      this.closeCategoryPop();
    },
    openCategoryPop() {
      popCategory.style.display = 'flex';
    },
    closeCategoryPop() {
      popCategory.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        popCategory.style.display = 'none';
        popCategory.children[0].style.animationName = 'popup';
      }, 300);
    },
  },
};
</script>
